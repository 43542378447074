import { differenceInDays } from 'date-fns/differenceInDays';
import { parse } from 'date-fns/parse';
import { addDays } from 'date-fns/addDays';
import { format, formatDate } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import { isEqual } from 'date-fns/isEqual';
import { isWithinInterval } from 'date-fns/isWithinInterval';

export const dateFormat = 'dd-MMM-yyyy';

export const offeringStartsFrom = parse('11-Jan-2025', dateFormat, new Date());
export const offeringEndsAt = parse('26-Feb-2025', dateFormat, new Date());
const offeringInterval = {
  start: offeringStartsFrom,
  end: offeringEndsAt,
};

export const parseDate = (date: Date | string) =>
  date instanceof Date ? date : parse(date, dateFormat, new Date());

export const getDatesAvailable = (
  from: Date | string = offeringStartsFrom,
  to: Date | string = offeringEndsAt,
) => {
  const daysAvailable = differenceInDays(parseDate(to), parseDate(from)) + 1;

  return Array.from({ length: daysAvailable }, (_, i) => {
    const date = parseDate(from);
    return addDays(date, i);
  });
};

export const getDateString = (date: Date | string) => {
  return formatDate(date, dateFormat);
};

export const bathingDates = [
  new Date('13 Jan 2025'),
  new Date('14 Jan 2025'),
  new Date('29 Jan 2025'),
  new Date('3 Feb 2025'),
  new Date('4 Feb 2025'),
  new Date('12 Feb 2025'),
  new Date('26 Feb 2025'),
];

export const getBathingDaysInInRange = (startDate: string, endDate: string) => {
  const start = parseDate(startDate);
  const end = parseDate(endDate);

  return bathingDates.filter((date) => date >= start && date <= end);
};

export const validateInRange = (date: Date) => {
  return date >= offeringStartsFrom && date <= offeringEndsAt;
};

export const validateInRangeFromString = (dateString: string) => {
  const date = parseDate(dateString);
  return date >= offeringStartsFrom && date <= offeringEndsAt;
};

export const hasValidDates = (startDate: string, endDate: string): boolean => {
  const start = parseDate(startDate);
  const end = parseDate(endDate);

  if (!isBefore(start, end)) {
    return false;
  }

  if (isEqual(start, end)) {
    return false;
  }

  if (!isWithinInterval(start, offeringInterval)) {
    return false;
  }

  if (!isWithinInterval(end, offeringInterval)) {
    return false;
  }

  return true;
};

export const hasValidBathingDates = (startDate: string, endDate: string): boolean => {
  if (!hasValidDates(startDate, endDate)) {
    return false;
  }

  const start = parseDate(startDate);
  const end = parseDate(endDate);

  const bathingDatesInRange = getBathingDaysInInRange(startDate, endDate);
  console.log(bathingDatesInRange);
  if (bathingDatesInRange.length > 0 && differenceInDays(end, start) + 1 < 3) {
    return false;
  }

  return true;
};

export const displayDate = (dateString: string): string => {
  try {
    // Parse the date string into a Date object
    const parsedDate = parse(dateString, 'dd-MMM-yyyy', new Date());

    // Format the Date object into a human-readable format
    return format(parsedDate, 'do MMMM yyyy'); // Example: '20th January 2025'
  } catch (e) {
    return dateString;
  }
};
